import '../../../assets/images/icon-01.svg'
import '../../../assets/images/dc-team-calendar.svg'
import angular from 'angular'
import template from './instructor-dashboard.html'

import Calendar from './calendar/instructor-calendar.module'
import TeamCalendar from './teamcalendar/instructor-teamcalendar.module'
import Agenda from './agenda/instructor-agenda.module'
import TeamAgenda from './teamagenda/instructor-teamagenda.module'
import Reportcards from './report-cards/instructor-upcoming-students-report-cards.module'
import PendingReportCards from './pending-report-cards/pending-report-cards.module'
import GradeGradedReportcards from './create-report-card/grade-graded-report-card.module'
import GradePassFailReportcards from './create-pass-fail-report-card/create-pass-fail-report-card.module'
import GradeGenericReportcards from './grade-generic-report-card/grade-generic-report-card.module'
import EditContactInfo from './edit/instructor-edit-contact-info.module'
import EditCertification from './certifications/instructor-edit-certification.module'
import LogHours from './log-hours/instructor-log-hours.module'
import Courses from './course/instructor-course.module'
import CoursesIndex from './courses/index/instructor-courses-index.module'
import Events from './event/instructor-event.module'
import Drive from './drive/instructor-view-drive.module'
import RequestTimeOff from './request-time-off/instructor-request-time-off.module'
import TimeoffRequests from './time-off-requests/instructor-view-time-off-requests.module'
import InstructorDashboardController from './instructor-dashboard.controller'
import StudentOrders from './student-orders/student-orders.module'
import StudentDrives from './student-drives/student-drives.module'
import ViewStudent from './view-student/view-student.module'
import InstructorCancelDrivingLesson from './cancel-drive/instructor-cancel-drive.module'
import InstructorStudentReportCards from './student-reportcards/student-reportcards.module'
import InstructorReportCard from './report-card/report-card.module'
import CreateDrive from './create-drive/instructor-create-drive.module'
import CreateOrder from './create-order/instructor-create-order.module'
import Orders from './orders/orders.module'
import CompleteDrive from './complete-drive/instructor-complete-drive.module'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.instructor.dashboard', {
            url: '/dashboard',
            template: template,
            controller: 'InstructorDashboardController',
            controllerAs: 'vm',
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];


export default angular.module('instructor.dashboard', [
    Calendar.name,
    TeamCalendar.name,
    Agenda.name,
    TeamAgenda.name,
    Reportcards.name,
    PendingReportCards.name,
    GradeGradedReportcards.name,
    GradePassFailReportcards.name,
    GradeGenericReportcards.name,
    EditContactInfo.name,
    EditCertification.name,
    LogHours.name,
    Courses.name,
    CoursesIndex.name,
    Events.name,
    Drive.name,
    RequestTimeOff.name,
    TimeoffRequests.name,
    StudentOrders.name,
    StudentDrives.name,
    ViewStudent.name,
    InstructorCancelDrivingLesson.name,
    InstructorStudentReportCards.name,
    InstructorReportCard.name,
    CreateDrive.name,
    CreateOrder.name,
    Orders.name,
    CompleteDrive.name,
])
    .config(config)
    .controller('InstructorDashboardController', InstructorDashboardController)
;

